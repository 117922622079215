/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'
import BlockRounded from 'components/elements/Blocks/BlockRounded'

// Interface
import { UspsProps } from 'components/flex/Usps/UspsShell'

const Content = styled(ParseContent)`
  & h2 {
    margin-bottom: 2rem;
  }


  text-align: justify;
`

const Image = styled(Plaatjie)`
  top: 0;
  right: 0;
  bottom: 0;
  z-index: -1;

  @media (min-width: 992px) {
    width: 100%;
  }

  @media (max-width: 991px) {
    width: 95%;
  }
`

const UspsWrapper = styled.div`
  @media (max-width: 991px) {
    & h2 {
      font-size: ${({ theme }) => theme.font.size.medium};
    }
  }
`

const UspBlockRounded = styled(BlockRounded)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  overflow: hidden;
`

const Gradient = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgb(99, 110, 255);
  background: linear-gradient(
    90deg,
    rgba(99, 110, 255, 1) 45%,
    rgba(255, 255, 255, 0) 65%,
    rgba(255, 255, 255, 1) 85%
  );
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
`

const Usps = ({ fields }: UspsProps) => (
  <section className="mb-5 pb-lg-5 pb-5">
    <div className="container py-lg-5 py-3">
      <div className="row justify-content-between">
        <div className="col-md-6 col-lg-5 mb-4 mb-lg-0">
          <Content content={fields.description} className="pe-lg-5" />
        </div>
        <UspsWrapper className="col-md-6 col-lg-7 mt-4 mt-md-0">
          {fields.usps?.map((usp, index) => (
            <UspBlockRounded
              className="position-relative mb-lg-5 mb-4 py-3 py-sm-4"
              key={index}
            >
              <div className="py-1 py-sm-3">
                <Gradient style={{ zIndex: 1 }} />
                <Image
                  image={usp?.image}
                  alt=""
                  className="position-absolute"
                  style={{ zIndex: 0 }}
                />
                <h2
                  className="text-white ms-5 mb-0 position-relative"
                  style={{ zIndex: 2 }}
                >
                  {usp?.title}
                </h2>
              </div>
            </UspBlockRounded>
          ))}
        </UspsWrapper>
      </div>
    </div>
  </section>
)

export default Usps
