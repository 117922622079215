/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'

// Third party
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'

// Images
import Arrow from 'img/arrow-rounded.inline.svg'

// Interface
import { UspsProps } from 'components/flex/Usps/UspsShell'

const Section = styled.section`
  background-color: rgba(214, 225, 253, 0.24);
  margin-bottom: 6rem;
  color: ${({ theme }) => theme.color.primary};
`

const Image = styled(Plaatjie)`
  width: 465px;
  height: 465px;
  right: -80px;
  top: calc(50% - 272.5px);

  @media (max-width: 991px) {
    opacity: 0.1;
  }
  @media (max-width: 575px) {
    width: 320px;
    height: 320px;
    right: calc(50% - 160px);
  }
`

const Usp = styled.div`
  @media (max-width: 575px) {
    & h2 {
      font-size: ${({ theme }) => theme.font.size.big};
    }
  }

  & .highlighted-letter {
    font-family: ${({ theme }) => theme.font.family.secondary};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    color: ${({ theme }) => theme.color.grey};
    font-size: 120px;
    line-height: 120px;
    width: 78px;
  }
`

const Icon = styled(Plaatjie)`
  min-width: 128px;
  height: 128px;
`

const Content = styled(ParseContent)``

const SvgWrapper = styled(motion.div)`
  width: 50px;
  height: 50px;
`

const Accordion = styled.div`
  margin-top: 1.9rem;

  @media (min-width: 576px) {
    margin-left: -1.4rem;
  }
`

const UspsAccordion = ({ fields }: UspsProps) => {
  const [currentIndex, setCurrentIndex] = useState(-1)

  const arrowVariants = {
    open: {
      rotate: 90,
    },
    collapsed: {
      rotate: 0,
    },
  }

  const descriptionVariants = {
    open: {
      opacity: 1,
      height: 'auto',
      overflow: 'hidden',
    },
    collapsed: {
      opacity: 0,
      height: 0,
      overflow: 'hidden',
    },
  }

  return (
    <Section className="position-relative">
      <Image
        image={fields.image}
        alt=""
        className="position-absolute rounded-circle"
      />

      <div className="position-relative container pt-5">
        <div className="row justify-content-between">
          <div className="col-lg-9">
            {fields.uspsvector?.map((usp, index) => (
              <Usp className="d-flex flex-column flex-sm-row mb-4" key={index}>
                <div className="d-flex justify-content-end">
                  <Icon
                    image={usp?.icon}
                    alt={usp?.title || ''}
                    className="position-relative w-100"
                  />
                </div>

                <Accordion className="d-flex flex-column flex-xl-row align-items-center">
                  <span className="highlighted-letter me-4">
                    {usp?.title?.charAt(0)}
                  </span>
                  <div>
                    <h2 className="mb-0">{usp?.title}</h2>
                    <Content content={usp?.shortdescription} />
                    <AnimatePresence>
                      {currentIndex === index && (
                        <motion.div
                          initial="collapsed"
                          animate="open"
                          exit="collapsed"
                          variants={descriptionVariants}
                          transition={{
                            duration: 0.8,
                            ease: [0.04, 0.62, 0.23, 0.98],
                          }}
                        >
                          <ParseContent
                            content={usp?.description}
                            className="py-4"
                          />
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>

                  <motion.div
                    initial="collapsed"
                    animate={currentIndex === index ? 'open' : 'collapsed'}
                    variants={arrowVariants}
                  >
                    <SvgWrapper
                      className="ms-4"
                      initial={{ rotate: 90 }}
                      onClick={() => setCurrentIndex(index)}
                      role="button"
                    >
                      <Arrow className="rounded-circle" />
                    </SvgWrapper>
                  </motion.div>
                </Accordion>
              </Usp>
            ))}
          </div>
          <div className="col-lg-7">
            <ParseContent content={fields.description} className="mt-3" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="d-flex flex-column flex-sm-row align-items-center ms-sm-5 mt-5">
              <Image image={fields.cta?.image} alt="" />
              <ParseContent
                content={fields.cta?.description}
                className="ms-sm-4"
              />
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}

export default UspsAccordion
