/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'
import Plaatjie from '@ubo/plaatjie'

// Components
import ParseContent from 'components/shared/ParseContent'

// Interface
import { UspsProps } from 'components/flex/Usps/UspsShell'

const Section = styled.section`
  color: ${({ theme }) => theme.color.primary};
  @media (min-width: 992px) {
    margin-bottom: 3rem;
  }
  @media (max-width: 991px) {
    margin-bottom: 6rem;
  }
`

const Image = styled(Plaatjie)`
  width: 140px;
  height: 140px;
`

const Title = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    color: ${({ theme }) => theme.color.grey};
    @media (min-width: 992px) {
      font-size: 100px;
      line-height: 100px;
    }
  }
`

const Usp = styled.div`
  @media (max-width: 575px) {
    & h2 {
      font-size: ${({ theme }) => theme.font.size.big};
    }
  }
`

const Icon = styled(Plaatjie)`
  width: 60px;
  height: 60px;
`

const UspsCta = ({ fields }: UspsProps) => (
  <Section className="pb-lg-5">
    <div className="container py-5">
      <div className="row">
        <div className="col-lg-10">
          <Title content={fields.title} className="mb-4" />
        </div>
      </div>
      <div className="row justify-content-between">
        <div className="col-lg-5">
          {fields.uspsvector?.map((usp, index) => (
            <Usp className="d-flex align-items-center mb-4" key={index}>
              <Icon image={usp?.icon} alt={usp?.title || ''} />
              <h2 className="ms-3 mb-0">{usp?.title}</h2>
            </Usp>
          ))}
        </div>
        <div className="col-lg-7">
          <ParseContent content={fields.description} className="mt-3" />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-6">
          <div className="d-flex flex-column flex-sm-row align-items-center ms-sm-5 mt-5">
            <Image image={fields.cta?.image} alt="" />
            <ParseContent
              content={fields.cta?.description}
              className="ms-sm-4"
            />
          </div>
        </div>
      </div>
    </div>
  </Section>
)

export default UspsCta
